export default class Api{

	static url = window.location.href.includes('localhost:3000') ? 'http://localhost:3000/api/' : 'https://jakubhyza.cz/api/';

	static async call(path = '',data = {}, method = 'GET'){
		path = Api.url + path;

		var request = {
			token: Api._token,
			body: data
		}

		try
		{
			var response = await fetch(path, {
				headers: {
					'Content-Type': 'application/json'
				},
				method: method,
				cache: 'no-cache',
				body: method === 'GET' ? undefined : JSON.stringify(request)
			});
			response = await response.json();
	
			console.log(response);
			if (this._token !== null && 'code' in response && response.code === 403)
				this.logoutPointer();
			else
				return response;
		}
		catch(e)
		{
			console.error(e);
			return null;
		}
	}
}