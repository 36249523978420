import React, {Component} from 'react';
import { Hidden, Visible } from 'react-grid-system';
import { Link } from 'react-router-dom';
import './Header.css';
import Navigation from './Navigation';
import Switch from './Switch';
import MobileNavigation from './MobileNavigation';

export default class Header extends Component {

	render(){
		return(
			<header>
				<Hidden xs sm md lg>
					<Link to="/">
						<img src="/img/logo.svg" alt="Jakub Hýža - Programátor" style={{
							transform: (this.props.hideLogo === undefined || this.props.hideLogo === null || this.props.hideLogo === false) ? 'translateX(-100% - 3rem)' : 'none'
						}} />
					</Link>
				<Navigation />
				{
					this.props.isWinter &&
					<Switch
						onClick={this.props.snowSwitch}
						value={this.props.snow}
						style={{
							float: 'right',
							marginTop: 'calc(1rem + 1.4em)',
							marginRight: '1rem'
						}}
					/>
				}
				</Hidden>
				<Visible xs sm md lg>
					<MobileNavigation />
				</Visible>		
			</header>
		);
	}
}