import React from 'react';
import './Button.css';

export default function Button(props){
	return(
		<a href={props.href} className="button" target={props.target}>
			<div style={{
				position: 'relative'
			}}>
				{props.children}
			</div>
		</a>
	);
}