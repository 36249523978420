import React from 'react';
import Content from '../components/Content';
import ProjectList from '../components/ProjectList';
import ProjectWidget from '../components/ProjectWidget';

export default function ProjectListPage(props){
	return(
		<Content>
			<h1>{props.list.title}</h1>
			{
				props.list.description !== undefined &&
				<p style={{
					paddingBottom: '1em'
				}}>{props.list.description}</p>
			}
			<ProjectList>
				{
					props.list.projects.map(project=>(
						<ProjectWidget key={project.link} project={project} type={props.type} />
					))
				}
			</ProjectList>
		</Content>
	);
}