import React from 'react';
import { Col, Row } from 'react-grid-system';

export default function ProjectList(props){
	return(
		<Row>
			{
				props.children.map((child,key)=>(
					<Col key={key} xs={12} md={6} xl={4}>
						{child}
					</Col>
				))
			}
		</Row>
	);
}