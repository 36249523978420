import React, { Component } from 'react';
import './Switch.css';

export default class Switch extends Component {

	state = {
		active: false
	}

	onClick = () => {
		this.setState({
			active: !this.state.active
		})

		if (this.props.onClick !== undefined)
			this.props.onClick();
	}

	render(){
		var active = (this.props.value !== null && this.props.value !== undefined) ? this.props.value === true : this.state.active;
		return(
			<div className={"switch-wrap"+(active ? ' active' : '')} onClick={this.onClick} style={this.props.style} >
				<div className={"switch"} />
			</div>
		);
	}

	
}