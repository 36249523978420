import React, { Component } from 'react';
import Api from '../Api';
import Loader from '../components/Loader';
import ProjectListPage from '../layout/ProjectListPage';


export default class ProjectsPage extends Component {

    state = { }

    componentDidMount(){
        this.updateData();
    }

    updateData = () => {
        Api.call('/projects.json')
        .then(response=>{
            this.setState(response)
        })
    }

    render(){
        if (this.state[this.props.match.params.type] === undefined){
            return(
                <Loader />
            );
        } else {
            return(
                <ProjectListPage type={this.props.match.params.type} list={this.state[this.props.match.params.type]} />
            );
		}
    }
}