import React, {Component} from 'react';
import './TextLoop.css';

export default class TextLoop extends Component {

	state = {
		index: 0,
		active: true
	}

	interval = null;

	componentDidMount() {
		this.interval = setInterval(()=>{
			this.next();
		},5000);
	}
	componentWillUnmount(){
		clearInterval(this.interval);
	}


	next = () => {
		var next = this.state.index + 1;
		if (next >= this.props.texts.length)
			next = 0;

		this.setState({
			active: false
		},()=>{
			setTimeout(()=>{
				this.setState({
					active: true,
					index: next
				})
			},2000)
		})
	}

	render(){
		return(
			<span className={"TextLoop " + (this.state.active ? 'active' : '')}>
				{
					this.props.texts[this.state.index]
				}
			</span>
		);
	}
}