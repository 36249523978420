import React, { Component } from 'react';
import { Col, Row } from 'react-grid-system';
import Content from '../components/Content';
import MaterialIcon from '../components/MaterialIcon';
import CursorForceEffect from '../components/CursorForceEffect';

export default class CollaborationPage extends Component {
	render(){
		return(
			<Content>
				<h1 style={{
					marginBottom: '0px'
				}}>Spolupráce</h1>
				<p style={{
					marginTop: '0px',
					marginBottom: '3em'
				}}>
					Lidé, se kterými mám uzavřenou spolupráci.<br />
				</p>
				<Row>
					<Col xs={12} md={3}>
						<a href="https://ayanadesign.eu/" target="_blank" rel="noopener noreferrer">
							<CursorForceEffect>
								<img src="/uploads/collab/andrea_stastna.jpg" alt="Andrea Šťastná" style={{borderRadius:'5px'}} />
							</CursorForceEffect>
						</a>
					</Col>
					<Col xs={12} md={9}>
						<h2 style={{
							fontWeight: 'normal',
							margin: '0px',
							fontSize: '2em'
						}}>Andrea Šťastná</h2>
						<p style={{
							margin: '0px'
						}}>Grafička</p>
						<p>
						Vystudovala jsem multimedia a teď pokračuji na oboru 3D interaktivní grafika a vizualizace. Věnuji se primárně 2D a 3D grafice a webdesignu, dále pak navrhování interiéru, fotce a videu, výjimečně tvořím i ilustrace na papír. Stojím si za férovým a přátelským přístupem, díky čemuž vždy s klientem dosáhneme návrhu podle našich představ.
						</p>
						<p>
							<MaterialIcon icon="email" /> <a href="mailto:ayana@ayanadesign.eu" target="_blank" rel="noopener noreferrer">ayana@ayanadesign.eu</a><br />
							<MaterialIcon icon="laptop" /> <a href="https://ayanadesign.eu/" target="_blank" rel="noopener noreferrer">https://ayanadesign.eu</a>
						</p>
						
					</Col>
				</Row>
				<br />
				<Row>
					<Col xs={12} md={3}>
						<a href="https://awanys.eu/" target="_blank" rel="noopener noreferrer">
							<CursorForceEffect>
								<img src="/uploads/collab/jakub_kamenik.png" alt="Jakub Kameník" style={{borderRadius:'5px'}} />
							</CursorForceEffect>
						</a>
					</Col>
					<Col xs={12} md={9}>
						<h2 style={{
							fontWeight: 'normal',
							margin: '0px',
							fontSize: '2em'
						}}>Jakub Kameník - Awanys</h2>
						<p style={{
							margin: '0px'
						}}>3D modelování</p>
						<p>
							Vývojář z Prahy, kterého baví tvořit a motivovat ostatní ke kreativitě. Od roku 2013 se věnuje YouTube, kde natáčí videa o vývoji her v Unity a modelování v Blenderu. Pracoval také krátce jako externí redaktor pořadu Indian. Nyní se živí jako freelancer v multimediálním oboru.
						</p>
						<p>
							<MaterialIcon icon="email" /> <a href="mailto:awanys@awanys.eu" target="_blank" rel="noopener noreferrer">awanys@awanys.eu</a><br />
							<MaterialIcon icon="laptop" /> <a href="https://awanys.eu/" target="_blank" rel="noopener noreferrer">https://awanys.eu</a>
						</p>
					</Col>
				</Row>
			</Content>
		);
	}
}