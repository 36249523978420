import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import HomePage from './pages/HomePage';
import ContactPage from './pages/ContactPage';
import SimpleReactLightbox from "simple-react-lightbox";
import Snowfall from 'react-snowfall'
import ProjectDetail from './pages/ProjectDetail';
import ProjectsPage from './pages/ProjectsPage';
import CollaborationPage from './pages/CollaborationPage';

class App extends Component {

	state = {
		snowOn: this.isWinter()
	}

	snowSwitch = () => {
		this.setState({
			snowOn: !this.state.snowOn
		})
	}

	isWinter(){
		var date = new Date();

		var month = date.getMonth();
		var day = date.getDate();

		return ((month < 2) || (month === 2 && day < 20) || (month === 11 && day >= 21));
	}

	render(){
		return (
			<div className="App">
				{
					this.state.snowOn &&
					<Snowfall />
				}

				<SimpleReactLightbox>
					<BrowserRouter>
						<Switch>
							<Route exact path="/">
								<Header hideLogo={true} isWinter={this.isWinter()} snowSwitch={this.snowSwitch} snow={this.state.snowOn} />
							</Route>
							<Route>
								<Header isWinter={this.isWinter()} snowSwitch={this.snowSwitch} snow={this.state.snowOn} />
							</Route>
						</Switch>

						
						<Switch>
							<Route exact path="/" component={HomePage} />
							<Route path="/contact" component={ContactPage} />
							<Route path="/collaboration" component={CollaborationPage} />
							<Route path="/project/:type/:id" component={ProjectDetail} />
							<Route path="/project/:type" component={ProjectsPage} />
						</Switch>
					</BrowserRouter>
				</SimpleReactLightbox>
			</div>
		);
	}
}

export default App;
