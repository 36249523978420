import React from 'react';
import { NavLink } from 'react-router-dom';
import './Navigation.css';

export default function Navigation(props){
	return(
		<nav className={`Navigation ${props.mobile ? 'mobile' : ''}`}>
			<NavLink activeClassName="active" to="/project/website">Webové&nbsp;stránky</NavLink>
			<NavLink activeClassName="active" to="/project/game">Hry</NavLink>
			<NavLink activeClassName="active" to="/collaboration">Spolupráce</NavLink>
			<NavLink activeClassName="active" to="/contact">Kontakt</NavLink>
		</nav>
	);
}