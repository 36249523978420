import React from 'react';
import { Link } from 'react-router-dom';

import style from './ProjectWidget.module.css';
import CursorForceEffect from './CursorForceEffect';

export default function ProjectWidget(props){
	return(
		<Link to={'/project/'+props.type + '/' + props.project.link} style={{
			color: 'white',
			textDecoration: 'none'
		}}>
			<CursorForceEffect>
				<div style={{
					marginBottom: '2em'
				}}>
					
					
						<div className={style.Thumbnail}>
							<img
								src={props.project.thumbnail}
								alt=""
							/>
						</div>
					
					
					<h2 className={style.Title}>{props.project.title}</h2>

					{
						props.project.tags.map(tag=>(
							<span key={tag} style={{
								fontSize: '1.1em',
								marginRight: '0.6em',
								color: '#a7a7a7'
							}}>
								{'//'+tag}
							</span>
						))
					}
				</div>
			</CursorForceEffect>
		</Link>
	);
}