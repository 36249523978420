import React from 'react';
import { Col, Hidden, Row } from 'react-grid-system';
import Content from '../components/Content';
import MaterialIcon from '../components/MaterialIcon';
import { SRLWrapper } from "simple-react-lightbox";
import Button from '../components/Button';
import DateTime from '../components/DateTime';


export default function ProjectDetailPage(props){
	return(
		<Content>
			{/* Nadpis */}
			{
				props.project.title !== undefined &&
				<h1 style={{
					marginBottom: '0px'
				}}>{props.project.title}</h1>
			}

			{/* Info */}
			<p style={{
				marginTop: '1em'
			}}>
				{
					props.project.date !== undefined &&
					<span>
						<MaterialIcon icon="access_time" /> <DateTime date={props.project.date} />
						<br />
					</span>
				}
				{
					props.project.technology !== undefined &&
					<span>
						<MaterialIcon icon="code" /> {props.project.technology.join(', ')}
						<br />
					</span>
				}
			</p>

			{/* Popis */}
			{
				props.project.description !== undefined &&
				<p>{props.project.description}</p>
			}

			{/* Tlačítka */}
			{
				props.project.buttons !== undefined &&
				<div>
					<Row>
						{
							props.project.buttons.length < 3 &&
							<Hidden xs sm>
								<Col xs={4/props.project.buttons.length} />
							</Hidden>
						}
						{
							props.project.buttons.map((btn,key)=>(
								<Col key={key} xs={12} md={4} style={{
									marginBottom: '20px',
									fontSize: '1.3em'
								}}>
									<Button href={btn.href} target="_blank">
										{btn.text}
									</Button>
								</Col>
							))
						}
					</Row>
				</div>
			}

			{/* Obrázky */}
			{
				props.project.images !== undefined &&
				<SRLWrapper options={{
					buttons: {
						showDownloadButton: false,
						showThumbnailsButton: false
					},
					translations: {
						autoplayText: "Přehrát",
						closeText: "Zavřít",
						fullscreenText: "Celá obrazovka",
						nextText: "Další",
						pauseText: "Pozastavit",
						previousText: "Předchozí"
					}
				}}>
					<Row>
						{
							props.project.images.map((img,key)=>(
								<Col xs={12} md={6} key={key} style={{
									marginBottom: '2em'
								}}>
									<img src={img.url} alt={img.caption} style={{
										cursor: 'pointer'
									}} />
									<span>{'//'} {img.caption}</span>
								</Col>
							))
						}
					</Row>
				</SRLWrapper>
			}
		</Content>
	);
}