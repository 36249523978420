import React from 'react';
import styles from './Loader.module.css';

export default function Loader(){
	return(
		<div className={styles.loaderWrap}>
			<div className={styles.loader}>
			</div>
		</div>
	);
}