import React from 'react';
import Content from '../components/Content';
import MaterialIcon from '../components/MaterialIcon';

export default function ContactPage(props){
	return(
		<Content>
			<h1>O mně</h1>
			<p>
				Zdravím,
				<br />
				Jmenuji se Jakub Hýža, je mi 20 let a zabývám se programováním, tvorbou webů a hraním her. Programování beru jako práci i jako koníček. Mezi mé nejoblíbenější programovací jazyky patří JavaScript, PHP a C#. Umím taky něco málo v C, C++ a Haskellu.
				<br />
				<br />
				Mám vystudovanou střední školu v oboru Informační Technologie.
				<br />
				V současné době studuji 2. ročník bakalářského studia, obor Informatika.
			</p>
			<p>
				<MaterialIcon icon="email" /> <a href="mailto:info@jakubhyza.cz" target="_blank" rel="noopener noreferrer">info@jakubhyza.cz</a>
			</p>
		</Content>
	);
}