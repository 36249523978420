import React, { Component } from 'react';
import style from './MobileNavigation.module.css';
import { Link } from 'react-router-dom';
import Navigation from './Navigation';

class MobileNavigation extends Component {

	state = {
		opened: false
	}

	toggleMenu = () => {

		document.body.style.overflow = this.state.opened ? 'unset' : 'hidden';

		this.setState({
			opened: !this.state.opened
		})
	}

	render() {
		return (
			<div>
				<div className={style.MobileHeader}>
					<Link to="/">
						<img src="/img/logo.svg" alt="Jakub Hýža - Programátor" onClick={()=>{if(this.state.opened){this.toggleMenu()}}} />
					</Link>
					<div className={`${style.BurgerButton} ${this.state.opened ? style.Opened : ''}`} onClick={this.toggleMenu} />
				</div>
				<div className={`${style.MobileMenu} ${this.state.opened ? style.Opened : ''}`}>
					<div className={style.MobileMenuContent} onClick={this.toggleMenu} >
						<Navigation mobile />
					</div>
				</div>
			</div>
		);
	}
}

export default MobileNavigation;