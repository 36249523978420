import React from 'react';
import { Container, setConfiguration } from 'react-grid-system';
import './Content.css';

setConfiguration({
	containerWidths: [540, 740, 960, 1000, 1000]
})

export default function Content(props){
	return(
		<div className="content">
			<Container style={{
				background: '#181320',
				boxShadow: 'box-shadow: 0px 0px 0 20px rgb(24, 19, 32)'
			}}>
				{props.children}
			</Container>
		</div>
	);
}