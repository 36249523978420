import React, { Component } from 'react';

class CursorForceEffect extends Component {

	constructor(props){
		super(props);

		this.selfElement = React.createRef();
	}

	state = {
		rotX: 0,
		rotY: 0,

		hover: false
	}

	onHoverEnter = () => {
		this.setState({
			hover: true
		})
	}

	onHoverLeave = () => {
		this.setState({
			hover: false,
			rotX: 0,
			rotY: 0
		})
	}

	onMouseMove = e => {
		
		if (!this.state.hover)
			return;

		var self = this.selfElement.current;
		var box = self.getBoundingClientRect();

		this.setState({
			rotX: -this.sizeRemap(e.clientY - box.y,0,box.height,-2,2),
			rotY: this.sizeRemap(e.clientX - box.x,0,box.width,-2,2)
		})
	}

	sizeRemap(x, inMin, inMax, outMin, outMax) {
		return (x - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
	}

	render() {
		return (
			<div ref={this.selfElement} onMouseMove={this.onMouseMove} onMouseEnter={this.onHoverEnter} onMouseLeave={this.onHoverLeave}>
				<div style={{
					transform: `perspective(250px) rotateX(${this.state.rotX}deg) rotateY(${this.state.rotY}deg)`,
					transition: this.state.hover ? 'initial' : 'transform 0.3s'
				}}>
					{this.props.children}
				</div>
			</div>
		);
	}
}

export default CursorForceEffect;