import React from 'react';

export default function MaterialIcon(props){
	return(
		<span class="material-icons" style={{
			transform: 'translateY(0.15em)'
		}}>
			{props.icon}
		</span>
	);
}