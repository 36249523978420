import React, {Component} from 'react';
import TextLoop from '../components/TextLoop';
import './HomePage.css';

export default class HomePage extends Component {

	textList = [
		"Programy, weby, hry",
		"Software podle vašich potřeb",
		"Web na míru? Tady jste správě",
	];

	render(){
		return(
			<div className="HomePage">
				<img src="/img/logo.svg" alt="Jakub Hýža" />
				<p>
					<span style={{
						display: 'inline-block',
						overflow: 'hidden',
						paddingRight: '3px'
					}}>
						{'//'}
					</span>
					<TextLoop texts={this.textList} />
				</p>
			</div>
		);
	}
}