import React from 'react';

export default function DateTime(props){

    var date = new Date(Date.parse(props.date));


    return(
        <span>
            {date.getDate()}. {['Leden','Únor','Březen','Duben','Květen','Červen','Červenec','Srpen','Září','Říjen','Listopad','Prosinec'][date.getMonth()]} {date.getFullYear()}
        </span>
    );
}