import React, { Component } from 'react';
import Api from '../Api';
import Loader from '../components/Loader';
import ProjectDetailPage from '../layout/ProjectDetailPage';


export default class ProjectDetail extends Component {

    state = {
        project: null
    }

    componentDidMount(){
        this.updateData();
    }

    updateData = () => {
        Api.call(this.props.match.params.type+'/'+this.props.match.params.id+'.json')
        .then(response=>{
            this.setState({
                project: response
            })
        })
    }

    render(){
        if (this.state.project === null){
            return(
                <Loader />
            );
        } else {
            return(
                <ProjectDetailPage project={this.state.project} />
            );
        }
    }
}